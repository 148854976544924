<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
    </vs-row>
    <div id="data-list-list-view" class="data-list-container">
      <div class="flex flex-col lg:hidden">
        <div
          v-for="order in users"
          :key="order._id"
          class="flex flex-row flex-wrap w-full"
          @click="viewDetailHandler(order._id)"
        >
          <!-- product -->
          <div class="flex flex-col justify-center">
            <div
              class="text-lg lg:text-2xl md:text-2xl lg:font-semibold md:font-semibold cursor-pointer"
            >
              {{ order.createdAt | date_formatter }}
            </div>
            <div class="text-lg">Order#: {{ order.orderNumber }}</div>
            <div
              class="text-lg lg:text-2xl md:text-2xl font-semibold text-primary lg:mt-2 md:mt-2"
            >
              ${{ order.total | formatDecimals }}
            </div>
            <div class="text-lg">Status: {{ getStatus(order) }}</div>
            <div class="text-lg">
              Items: {{ getQuantityTotal(order.items) }}
            </div>
          </div>

          <div class="hidden flex flex-col w-1/3 justify-center items-center">
            <vx-tooltip text="Invoice PDF" v-if="getStatus(order) === 'Paid'">
              <a
                :href="getPdf(order)"
                :download="getName(order)"
                target="_blank"
              >
                <vs-button
                  type="flat"
                  style="float: left"
                  color="success"
                  class="m-1"
                  >Invoice PDF</vs-button
                >
              </a>
            </vx-tooltip>
          </div>

          <vs-divider />
        </div>
      </div>

      <vs-table
        class="hidden lg:block"
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-left items-top"
        >
          <vs-select
            placeholder="10"
            autocomplete
            v-model="dataTableParams.limit"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in limitOptions"
            />
          </vs-select>
          <!-- <div style="width: 200px" class="ml-5">
            <Clinic :onChange="() => {}" :title="''" />
          </div> -->
        </div>

        <template slot="thead">
          <vs-th sort-key="orderNumber">Order Id</vs-th>
          <vs-th>No. of Orders</vs-th>
          <vs-th sort-key="createdAt">Order Date</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th>Total</vs-th>
          <vs-th>Items</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p
                v-for="supplierOrder in data[indextr].supplierOrders"
                :key="supplierOrder._id"
              >
                {{ supplierOrder.orderDetails.orderNumber }}
              </p>
            </vs-td>
            <vs-td>
              {{ data[indextr].supplierOrders.length }}
            </vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              data[indextr].createdAt | date_formatter
            }}</vs-td>
            <vs-td :data="data[indextr].status">
              {{ getStatus(data[indextr]) }}
            </vs-td>
            <vs-td :data="data[indextr].total"
              >{{ currency }} ${{ data[indextr].total | formatDecimals }}</vs-td
            >
            <vs-td :data="data[indextr].total">{{
              getQuantityTotal(data[indextr].items)
            }}</vs-td>

            <vs-td :data="data[indextr]._id">
              <div class="flex flex-no-wrap">
                <!-- <vx-tooltip
                  v-if="
                    data[indextr].status === 'Failed' ||
                    data[indextr].status === 'Payment-Failed'
                  "
                  text="Retry Payment"
                  position="top"
                >
                  <vs-button
                    color="#3dc9b3"
                    type="border"
                    @click="selectedToPay(data[indextr])"
                    style="padding: 4px 10px"
                    >Pay Now</vs-button
                  >
                </vx-tooltip> -->

                <vx-tooltip
                  text="Invoice PDF"
                  v-if="
                    data[indextr] &&
                    data[indextr].orderinvoices &&
                    data[indextr].orderinvoices.pdfLink &&
                    data[indextr].status === 'Paid'
                  "
                >
                  <a
                    :href="getPdf(data[indextr])"
                    :download="getName(data[indextr])"
                    target="_blank"
                  >
                    <vs-button
                      type="flat"
                      style="float: left"
                      size="small"
                      color="success"
                      class="m-1"
                      >Invoice PDF</vs-button
                    >
                  </a>
                </vx-tooltip>
                <vx-tooltip text="View Order Details">
                  <vs-button
                    style="float: left"
                    type="border"
                    size="small"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-eye"
                    color="success"
                    class="m-1"
                  ></vs-button>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-popup :active.sync="popUpPay" title="Pay Confirmation">
        <p>Are you sure you want to pay this order?</p>

        <vs-row vs-justify="center" vs-mt="6">
          <div class="w-full">
            <p>
              <strong>Order Number:</strong>
              {{ selectedToPayData.orderNumber }}
            </p>
            <p>
              <strong>Total Amount:</strong>
              {{ selectedToPayData.total | formatDecimals | currency }}
            </p>
            <p class="pt-3">
              <strong>Selected payment methods</strong>
            </p>
            <ul class="m-0 p-0">
              <!-- OPTION 1 -->

              <!-- OPTION 2 -->
              <li v-for="(card, index) in paymentMethods" :key="index">
                <div class="flex flex-row p-4 px-6">
                  <div class="pr-4 pt-1">
                    <vs-radio
                      v-model="paymentType"
                      :vs-value="
                        card.cardId ? card.cardId : 'SavedClinicBankAccount'
                      "
                    />
                  </div>
                  <div v-if="card.cardId">
                    <p>{{ card.brand }}</p>
                    <p>XXXX XXXX XXXX {{ card.last4 }}</p>
                    <p>
                      {{ card.exp_month }} /
                      {{ card.exp_year }}
                    </p>
                  </div>
                  <div v-if="!card.cardId">
                    <p>{{ card.accountHolderName }}</p>
                    <p>{{ card.accountHolderEmail }}</p>
                    <p>
                      {{ card.bsb }} /
                      {{ card.accountNumber }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <vs-popup
              class="holamundo"
              title="Add payment method"
              :active.sync="showAddPayment"
            >
              <vs-tabs>
                <vs-tab label="Credit Card" @click="paymentType = 'CreditCard'">
                  <stripe-elements
                    ref="stripeElement"
                    v-bind:title="saveCreditCard"
                    :pk="stripePublishableKey"
                    :amount="totalPrice"
                    locale="en"
                    @token="tokenCreated"
                    @loading="loading = $event"
                  >
                  </stripe-elements>
                  <vs-button @click="submit">Confirm</vs-button>
                </vs-tab>
                <vs-tab
                  label="Bank Account"
                  @click="paymentType = 'ClinicBankAccount'"
                >
                  <BankSetup
                    :totalPrice="totalPrice"
                    :financialDetails="financialDetails"
                    :isNewClinicBankAccount="isNewClinicBankAccount"
                    :shippingDetails="shippingDetails"
                    :paymentType="'ClinicBankAccount'"
                    :repayment="true"
                    @onClinicBankPaymentSuccess="clinicBankPaymentSuccess"
                  ></BankSetup>
                </vs-tab>
              </vs-tabs>
            </vs-popup>
            <div
              class="border py-3 mt-5"
              style="border-bottom: solid 1px #e0e0e0"
            >
              <div
                class="flex flex-row bg-primary rounded-lg text-white cursor-pointer p-2"
                style="width: 230px"
                @click="openPopup"
              >
                <plus-icon size="1.5x" class="custom-class"></plus-icon>
                <div class="pl-4 pt-1">Add Payment Method</div>
              </div>
            </div>
          </div>
        </vs-row>
        <vs-row vs-mt="6">
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="right">
              <vs-button size="lg" color="primary" @click="retryOrder()"
                >Yes, pay</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button size="lg" color="danger" @click="popUpPay = false"
                >Not now</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </vs-popup>

      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import requestDoctorAssociation from "../../others/requestDoctorAssociation";
import inviteUser from "../../others/inviteUser";
import { mapActions } from "vuex";
import BankSetup from "../BankSetup";
import StripeElements from "../StripeElements";
import Clinic from "../../shop/clinic";
import { PlusIcon } from "vue-feather-icons";

export default {
  name: "ClinicOrderList",
  components: {
    "request-doctor": requestDoctorAssociation,
    inviteUser,
    BankSetup,
    StripeElements,
    Clinic,
    PlusIcon,
  },
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    inTabEdit: {
      type: Boolean,
      default: false,
    },
    viewDetailRoute: {
      type: String,
      default: "OrderDetail",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNewClinicBankAccount: false,
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      saveCreditCard: true,
      popUpPay: false,
      noDataText: "Loading..",
      clinicCount: 0,
      customerCSVurl: false,
      isMounted: false,
      totalDocs: 0,
      currency: process.env.VUE_APP_CURRECY,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
      },
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      awaitingSearch: null,
      selectedToPayData: {
        orderNumber: "",
        total: "",
      },
      cardDetails: [],
      defaultCard: null,
      paymentType: null,
      financialDetails: null,
      hasCreditCards: false,
      users: [],
      showAddPayment: false,
      shippingDetails: null,
      token: null,
      totalPrice: null,
      isRepayment: false,
      cardId: null,
      paymentMethods: [],
    };
  },
  methods: {
    ...mapActions("ecommerce", [
      "fetchOrderedList",
      "fetchOrderedListAdmin",
      "retryPayment",
    ]),
    ...mapActions("general", [
      "fetchUserDetails",
      "fetchCardDetails",
      "fetchCardDetailsList",
      "fetchClinicPaymentDetails",
    ]),
    getClinicPaymentDetails() {
      if (sessionStorage.getItem("doctorClinicSelectedClinic")) {
        this.fetchClinicPaymentDetails(
          sessionStorage.getItem("doctorClinicSelectedClinic")
        )
          .then((res) => {
            this.paymentMethods = res.data.data;
            const result = this.paymentMethods.filter((val) => val.isDefault);
            if (result.length > 0) {
              this.paymentType = result[0].cardId
                ? result[0].cardId
                : "SavedClinicBankAccount";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getQuantityTotal(data) {
      return data.reduce((a, b) => a + (b["quantity"] || 0), 0);
    },
    clinicBankPaymentSuccess(val) {
      this.paymentType = "ClinicBankAccount";
      setTimeout(() => {
        this.retryOrder(val);
      }, 500);
    },
    tokenCreated(token) {
      this.$vs.loading();
      this.retryOrder({
        source: token.id,
        amount: this.totalPrice, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
      });
    },
    submit() {
      // console.log("laksdjfklasjflasfg");
      this.$refs.stripeElement.submit();
      this.paymentType = "CreditCard";
    },
    async getUserDetail() {
      const userDetail = await this.fetchUserDetails();
      if (
        userDetail.data.data.paymentDetails &&
        userDetail.data.data.paymentDetails.cardId
      ) {
        const cardDetails = await this.fetchCardDetails();
        this.defaultCard = cardDetails.data.data;
      }
      const cardDetailsList = await this.fetchCardDetailsList();
      if (
        cardDetailsList.data.data.length > 0 &&
        userDetail.data.data.paymentDetails.cardId
      ) {
        this.hasCreditCards = true;
        this.paymentType = "SavedCard";
      }
      this.cardDetails = cardDetailsList.data.data;
      this.isAccessPaymentTerms =
        typeof userDetail.data.data.isAccessPaymentTerms !== "undefined"
          ? userDetail.data.data.isAccessPaymentTerms
          : false;

      if (userDetail.data.data.financialDetails) {
        this.financialDetails = userDetail.data.data.financialDetails;
        this.isNewClinicBankAccount = false;
      }

      return this.formatUserDetailResponse(userDetail.data.data);
    },
    openPopup() {
      this.popUpPay = false;
      this.showAddPayment = true;
    },
    formatUserDetailResponse(userDetail) {
      return {
        clinicName: userDetail.clinicName,
        address: userDetail.address,
        email: userDetail.email,
      };
    },
    getPdf(row) {
      if (row.orderinvoices) {
        return row.orderinvoices.pdfLink;
      }
      return "#";
    },
    getName(row) {
      if (row.orderinvoices) {
        const user = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        );

        let name = user.clinicName;

        if (user.userType === "doctorClinic") {
          name = user.companyName;
        }

        return `${row.orderinvoices.invoiceNumber}${name ? ` ${name}` : ""}`;
      }
      return "#";
    },
    selectedToPay(data) {
      this.isRepayment = true;
      this.totalPrice = data.total;
      this.popUpPay = true;
      this.selectedToPayData = data;
    },
    getPaymentType() {
      if (
        this.paymentType == "CreditCard" ||
        this.paymentType == "SavedCard" ||
        this.paymentType == "ClinicBankAccount" ||
        this.paymentType == "SavedClinicBankAccount" ||
        this.paymentType == "PaymentTermsInvoice"
      ) {
        return this.paymentType;
      } else if (this.paymentType) {
        return "StoredCard";
      }
    },
    async retryOrder(data = {}) {
      this.$vs.loading();
      const orderId = this.selectedToPayData._id;
      let accountHolderName = "";
      let accountHolderEmail = "";
      if (this.financialDetails) {
        accountHolderName = this.financialDetails.accountHolderName;
        accountHolderEmail = this.financialDetails.accountHolderEmail;
      }
      await this.retryPayment({
        orderId,
        paymentType: this.getPaymentType(),
        cardId: this.paymentType,
        accountHolderName,
        accountHolderEmail,
        ...data,
      })
        .then((res) => {
          this.isRepayment = false;
          this.popUpPay = false;
          this.showAddPayment = false;
          this.$vs.loading.close();
          this.getOrderedProductList();
          this.$vs.notify({
            title: "Retry Payment",
            text: "Successfull",
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          this.dataTableParams.limit = 10;
        })
        .catch((err) => {
          this.showAddPayment = false;
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
          setTimeout(() => {
            this.popUpPay = false;
          }, 500);
        });
    },
    getStatus(item) {
      if (item.isPaymentTerms && item.status !== "Paid") {
        return "Payment Terms";
      }
      return item.status;
    },
    getOrderedProductList() {
      try {
        this.$vs.loading();
        this.users = [];
        if (this.$route.params.clinicId) {
          this.fetchOrderedList({
            ...this.dataTableParams,
            clinicId: this.$route.params.clinicId,
          })
            .then((res) => {
              this.$vs.loading.close();
              this.users = res.data.data.docs;
              this.totalDocs = res.data.data.pagination.total;
              this.page = res.data.data.pagination.page;
              this.serverResponded = true;
              this.noDataText = "No Ordered Item Available";
            })
            .catch((error) => {
              if (error.status == 403)
                this.noDataText =
                  "Permission needed! please contact administrator.";
            })
            .finally((e) => {
              this.$vs.loading.close();
            });
        } else {
          this.fetchOrderedList(this.dataTableParams)
            .then((res) => {
              this.$vs.loading.close();
              this.users = res.data.data.docs;
              this.totalDocs = res.data.data.pagination.total;
              this.page = res.data.data.pagination.page;
              this.serverResponded = true;
              this.noDataText = "No Ordered Item Available";
            })
            .catch((error) => {
              if (error.status == 403)
                this.noDataText =
                  "Permission needed! please contact administrator.";
            })
            .finally((e) => {
              this.$vs.loading.close();
            });
        }
      } catch (e) {
        this.$vs.loading.close();
        if (e.status == 403)
          this.noDataText = "Permission needed! please contact administrator.";
      }
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getOrderedProductList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getOrderedProductList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getOrderedProductList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: this.viewRoute, params: { doctorId: id } });
    },
    viewDetailHandler(id) {
      const userType = this.$store.state.AppActiveUser.userType;
      const routesByUserType = {
        doctor: "DoctorOrderDetail",
        nurse: "NurseOrderDetail",
        doctorClinic: "DoctorClinicOrderDetail",
        admin: "AdminOrderDetail",
        basic: "BasicOrderDetail",
      };

      let name;
      if (this.$route.path.includes("org-owner")) {
        name = "OrgOwnerOrderDetail";
      } else if (this.$route.path.includes("super-admin")) {
        name = "superAdminStoreViewOrder";
      } else {
        name = routesByUserType[userType] || this.viewDetailRoute;
      }

      this.$router.push({
        name,
        params: { orderId: id },
      });
    },

    createDoctorHandler() {
      this.$router.push({ name: this.createRoute });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getDoctorsList();
        this.getOrderedProductList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getDoctorsList();
        this.getOrderedProductList();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getDoctorsList();
        this.getOrderedProductList();
      }
    },
    showAddPayment: function (current, prev) {
      if (!current && this.isRepayment) {
        this.popUpPay = true;
      }
    },
    // "$store.state.clinicId": function () {
    //   this.getOrderedProductList();
    // },
  },
  created() {
    this.getOrderedProductList();
    this.getClinicPaymentDetails();
    this.getUserDetail();
    if (
      this.$store.state.AppActiveUser.paymentDetails &&
      this.$store.state.AppActiveUser.paymentDetails.customerId &&
      this.$store.state.AppActiveUser.paymentDetails.mandate &&
      this.$store.state.AppActiveUser.paymentDetails.mandate.payment_method &&
      this.$store.state.AppActiveUser.paymentDetails.mandate.id
    ) {
      this.paymentType = "SavedClinicBankAccount";
    }
  },
};
</script>

<style scoped></style>
